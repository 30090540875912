module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"undefined","siteUrl":"https://michaelbao.dev","matomoUrl":"undefined","localScript":"/piwik.js"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
